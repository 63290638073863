/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2025                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontadminmenu.js                                  !
  !  Desc. : Nodejs Digines Front Admin menu Modal              !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 22/03/2025                                         !
  !                                                             !
  !  0.1: Creation                                              !
  !  1.0: Adaptation to new platform                            !
  !  1.1: Admin reset password and Log Files download           !
  !  1.2: Add creation record in ticket detail                  !
  !  1.3: On reopen set state 'Requesting Digitech response'    !
  !  2.1: New display for Ticket details                        !
  !  2.2.0: Severity text and Product in Tickets list           !
  !  2.3.0: Fix URL for Digitech support                        !
  !  2.4.0: Add Reset HealthCheck for Admin                     !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton, Container, Card, CardActionArea, CardContent, Typography
} from "@mui/material";
import {Close} from "@mui/icons-material";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LockResetIcon from '@mui/icons-material/LockReset';
/*
--- Digitech products
*/
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = frontmainModal_e.noModal;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminFaqCard                             !
! Description: JSX Card Admin FAQ                             !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminFaqCard(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 300, height: 180, 'border-radius': '20px', border: 1}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.adminFaqModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <LiveHelpIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h6" component="div">
                        {locTrans.comtransGet_m("adminMenu", "titleFaq")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminDocCard                             !
! Description: JSX Card Admin Doc                             !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminDocCard(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 300, height: 180, 'border-radius': '20px', border: 1}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.adminDocModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <MenuBookIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h6" component="div">
                        {locTrans.comtransGet_m("adminMenu", "titleDoc")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminReportsCard                         !
! Description: JSX Card Admin Report Health checking          !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminReportsCard(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 300, height: 180, 'border-radius': '20px', border: 1}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.adminReportsModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <FactCheckIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography variant="h6" component="div">
                        {locTrans.comtransGet_m("adminMenu", "titleHealthCheck")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminLogCard                             !
! Description: JSX Card Admin get Log Files                   !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminLogCard(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 300, height: 180, 'border-radius': '20px', border: 1}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.adminLogModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <SaveAltIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography variant="h6" component="div">
                        {locTrans.comtransGet_m("adminMenu", "titleLogFile")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminResetPassword                       !
! Description: JSX Card Admin Reset User Password             !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminResetPassword(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 300, height: 180, 'border-radius': '20px', border: 1}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.adminPasswordModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <LockResetIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography variant="h6" component="div">
                        {locTrans.comtransGet_m("adminMenu", "titleResetPassword")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXContents                                 !
! Description: JSX Admin menu Contents, local definition      !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXContents(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Set 3 tiles by row
    */
    return (
        <Container maxWidth="xl" sx={{mt: 6, mb: 4}}>
            <Grid container spacing={4} direction="column" justifyContent="space-evenly"
                  sx={{textAlign: "-webkit-center"}}>
                <Grid container item spacing={2} justifyContent="space-around">
                    <Grid item xs>
                        <LocJSXAdminFaqCard ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                        <LocJSXAdminDocCard ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                        <LocJSXAdminReportsCard ctx={locCtx}/>
                    </Grid>
                </Grid>
                <Grid container item spacing={2} justifyContent="space-around" sx={{pb: "24px"}}>
                    <Grid item xs>
                        <LocJSXAdminLogCard ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                        <LocJSXAdminResetPassword ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );

}

/*
+-------------------------------------------------------------+
! Routine : LocJSXAdminMenu                                   !
! Description: JSX Admin Menu, local definition               !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminMenu(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Admin Menu Modal
    */
    return (
        <div>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <AdminPanelSettingsIcon/>
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m("adminMenu", "title")}
                    </Box>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                    sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{pb: 0}}>
                <LocJSXContents ctx={locCtx}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)} sx={{mt: 0, mr: 2, pt: 0}}>
                    {locTrans.comtransGet_m("common", "return")}
                </Button>
            </DialogActions>
        </div>);
}

/*=============== Exported functions ===========================*/

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontadminMenuJSX                                 !
! Description: JSX Admin menu Modal                           !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontadminmenuJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Return the Modal
    */
    return (<Dialog open={true} maxWidth="650px">
        <LocJSXAdminMenu ctx={locCtx}/>
    </Dialog>);
}
