/*
+-------------------------------------------------------------+
! CODE SOURCE MATERIALS                                       !
! DIGITECH CONFIDENTIAL                                       !
!                                                             !
! DIGITECH Platform Code                                      !
! (C) COPYRIGHT DIGITECH 2022-2025                            !
! Licensed Internal Code - Property of DIGITECH               !
+-------------------------------------------------------------+
+-------------------------------------------------------------+
!                                                             !
!File: fronttrans.js                                          !
!Desc. : Nodejs Digines Front translation table               !
!                                                             !
!Author: D.ESTEVE                                             !
!Modif.: 22/03/2025                                           !
!                                                             !
!  0.1: Creation                                              !
!  1.0: Adaptation to new platform                            !
!  1.1: Admin reset password and Log Files download           !
!  1.2: Add creation record in ticket detail                  !
!  1.3: On reopen set state 'Requesting Digitech response'    !
!  2.1: New display for Ticket details                        !
!  2.2.0: Severity text and Product in Tickets list           !
!  2.3.0: Fix URL for Digitech support                        !
!  2.4.0: Add Reset HealthCheck for Admin                     !
+-------------------------------------------------------------+
*/
/*=============== Definitions ==================================*/
/*
--- Diginesfront translation table
*/
export const fronttrans = {
    "common": {
        "cancel": {
            en: "Cancel",
            fr: "Annuler"
        }, "return": {
            en: "Return",
            fr: "Retour"
        }, "yes": {
            en: "Yes",
            fr: "Oui"
        }, "no": {
            en: "No",
            fr: "Non"
        }, "notAvailable": {
            en: "... Under development ...",
            fr: "... En cours de développement ..."
        }
    },
    "login": {
        "signIn": {
            en: "Sign in to your Digitech account",
            fr: "Accès à votre compte Digitech"
        }, "emailAddress": {
            en: "Email Address",
            fr: "Adresse de courriel"
        }, "password": {
            en: "Password",
            fr: "Mot de passe"
        }, "submit": {
            en: "Connect",
            fr: "Se connecter"
        }, "forgot": {
            en: "Forgot password ?",
            fr: "Mot de passe oublié ?"
        }, "new": {
            en: "Activate your account ?",
            fr: "Activer votre compte ?"
        }, "errorTitle": {
            en: "Connection failed",
            fr: "Problème de connexion"
        }, "errorMessage": {
            en: "Details",
            fr: "Détails"
        }
    },
    "main": {
        "title": {
            en: "Requests management",
            fr: "Gestion des demandes"
        }, "changeClient": {
            en: "Select another entity",
            fr: "Sélectionner une autre entité"
        }, "signout": {
            en: "Signout",
            fr: "Se déconnecter"
        }, "cookies": {
            en: "This application doesn't use any Cookie",
            fr: "Cette application n'utilise aucun Cookie"
        }
    },
    "ticketsList": {
        "title": {
            en: "Requests browsing",
            fr: "Consultation des demandes"
        }, "description": {
            en: "Display the list and details of requests from your entity.",
            fr: "Consultation des demandes émises par votre entité"
        }, "status": {
            en: "Requests status",
            fr: "Etat des demandes"
        }, "open": {
            en: "Open",
            fr: "Ouvertes"
        }, "close": {
            en: "Close",
            fr: "Fermées"
        }, "all": {
            en: "All",
            fr: "Toutes"
        }, "reference": {
            en: "Reference",
            fr: "Référence"
        }, "product": {
            en: "Product",
            fr: "Produit"
        }, "shortDescription": {
            en: "Short description",
            fr: "Description simplifiée"
        }, "author": {
            en: "Author",
            fr: "Auteur"
        }, "creationDate": {
            en: "Creation date",
            fr: "Date de création"
        }, "currentStatus": {
            en: "Status",
            fr: "Etat"
        },
    },
    "ticketDetail": {
        "title": {
            en: "Request ",
            fr: "Demande "
        }, "description": {
            en: "Display the request details.",
            fr: "Affiche les détails de la demande."
        }, "add": {
            en: "Add a note",
            fr: "Ajout d'une note"
        }, "reopen": {
            en: "Reopen the request",
            fr: "Réouvrir la demande"
        }, "status": {
            en: "Request status",
            fr: "Etat de la demande"
        }, "open": {
            en: "Open",
            fr: "Ouverte"
        }, "close": {
            en: "Close",
            fr: "Fermée"
        }, "reference": {
            en: "Reference",
            fr: "Référence"
        }, "shortDescription": {
            en: "Short description",
            fr: "Description simplifiée"
        }, "author": {
            en: "Author",
            fr: "Auteur"
        }, "email": {
            en: "Email",
            fr: "Courriel"
        }, "owner": {
            en: "Owner",
            fr: "Propriétaire"
        }, "product": {
            en: "Product",
            fr: "Produit"
        }, "origin": {
            en: "Origin",
            fr: "Origine"
        }, "form": {
            en: "Created with the Web portail",
            fr: "Créée avec le portail Web "
        }, "digitech": {
            en: "Created by the Digitech support",
            fr: "Créée par le support Digitech"
        }, "date": {
            en: "Date",
            fr: "Date"
        }, "creationDate": {
            en: "Creation date",
            fr: "Date de création"
        }, "modificationDate": {
            en: "Modification date",
            fr: "Date de modification"
        }, "type": {
            en: "Type",
            fr: "Type"
        }, "assignee": {
            en: "Assignee",
            fr: "Destinataire"
        }, "object": {
            en: "Object",
            fr: "Objet"
        }, "detail": {
            en: "Detail",
            fr: "Détail"
        }, "flux": {
            en: "Flux",
            fr: "Etape"
        }, "opening": {
            en: "Creation",
            fr: "Création"
        }, "closing": {
            en: "Closing",
            fr: "Fermeture"
        }, "attachedFiles": {
            en: "Attached files",
            fr: "Fichiers attach&eacute;s"
        }, "listAttachedFiles": {
            en: "Attached files:",
            fr: "Fichiers attachés :"
        }, "noAttachedFile": {
            en: "No file attached",
            fr: "Aucun fichier attaché"
        }, "noDetail": {
            en: "No text present",
            fr: "Aucun texte présent"
        }, "reopening": {
            en: "Reopening the request",
            fr: "Réouverture de la demande"
        }, "search": {
            en: "Search...",
            fr: "Rechercher..."
        }, "expand": {
            en: "Expand all",
            fr: "Tout déplier"
        }, "collapse": {
            en: "Collapse all",
            fr: "Tout plier"
        }
    },
    "ticketCreation": {
        "title": {
            en: "New request creation",
            fr: "Création d'une nouvelle demande"
        }, "description": {
            en: "Create a new request to Digitech support.",
            fr: "Création d'une nouvelle demande au support Digitech."
        }, "create": {
            en: "Create",
            fr: "Création"
        }, "summary": {
            en: "Request summary.",
            fr: "Titre de la demande."
        }, "product": {
            en: "Product.",
            fr: "Produit."
        }, "files": {
            en: "Add files",
            fr: "Ajout de fichiers"
        }, "nbFiles": {
            en: "Number of selected files: ",
            fr: "Nombre de fichiers sélectionnés : "
        }, "completed": {
            en: "Your new request has been created with the reference:",
            fr: "Votre nouvelle demande a été créée avec la référence :"
        }
    },
    "ticketAddComment": {
        "titleComment": {
            en: "Add a new note to the request",
            fr: "Ajout d'une nouvelle note à la demande"
        }, "titleReopen": {
            en: "Reopen the request",
            fr: "Réouverture de la demande"
        }, "add": {
            en: "Add",
            fr: "Ajouter"
        }, "reopen": {
            en: "Reopen the request",
            fr: "Réouvrir la demande"
        }, "summary": {
            en: "Note summary.",
            fr: "Titre de la note."
        }, "type": {
            en: "Type.",
            fr: "Type."
        }, "comment": {
            en: "Note.",
            fr: "Note."
        }, "addCompleted": {
            en: "New note successfully added to the request:",
            fr: "Nouvelle note ajoutée avec succès à la demande :"
        }, "reopenCompleted": {
            en: "Request successfully reopened:",
            fr: "Demande réouverte avec succès :"
        }, "files": {
            en: "Add files",
            fr: "Ajout de fichiers"
        }, "nbFiles": {
            en: "Number of selected files: ",
            fr: "Nombre de fichiers sélectionnés : "
        }
    },
    "ticketAddFiles": {
        "title": {
            en: "Select files to upload",
            fr: "Sélection des fichiers à remonter"
        }, "add": {
            en: "Click here for selecting a file or drag it to here.",
            fr: "Cliquer ici pour sélectionner un fichier ou le faire glisser jusqu'ici."
        }, "submit": {
            en: "Add",
            fr: "Ajouter"
        }
    },
    "passwordChange": {
        "title": {
            en: "Change your password",
            fr: "Changer votre mot de passe"
        }, "description": {
            en: "Account management: Change your password.",
            fr: "Gestion de votre compte: Changer votre mot de passe."
        }, "emailAddress": {
            en: "Email Address",
            fr: "Adresse de courriel"
        }, "oldPassword": {
            en: "Old password.",
            fr: "Ancien mot de passe."
        }, "newPassword": {
            en: "New password.",
            fr: "Nouveau mot de passe."
        }, "twicePassword": {
            en: "New password twice.",
            fr: "Nouveau mot de passe une deuxième fois."
        }, "passwordRules": {
            en: "Password should have at least: 8 characters, 1 lowercase letter, 1 uppercase letter, 1 numeric character.",
            fr: "Le mot de passe doit avoir au moins: 8 caractères, 1 minuscule, 1 majuscule, 1 chiffre."
        }, "change": {
            en: "Change",
            fr: "Changer"
        }, "completed": {
            en: "Password successfully changed !",
            fr: "Changement du mot de passe réalisé avec succès !"
        }
    },
    "passwordReset": {
        "titleCreate": {
            en: "Activate your account",
            fr: "Activer votre compte"
        }, "titleReset": {
            en: "Request a new password",
            fr: "Demander un nouveau mot de passe"
        }, "emailAddress": {
            en: "Email Address",
            fr: "Adresse de courriel"
        }, "request": {
            en: "Start the request",
            fr: "Lancer la demande"
        }, "completed": {
            en: "New password sent to your Email address:",
            fr: "Un nouveau mot de passe a été envoyé à votre adresse Email :"
        }
    },
    "clientSet": {
        "title": {
            en: "Select the searched entity",
            fr: "Sélectionner l'entité recherchée"
        }, "searchField": {
            en: "Enter a part of the searched entity name",
            fr: "Entrer une partie du nom de l'entité recherchée"
        }, "select": {
            en: "Select an entity name",
            fr: "Sélectionner une entité"
        }, "noClient": {
            en: "No entity found",
            fr: "Aucune entité de trouvée"
        }, "submit": {
            en: "Validate",
            fr: "Valider"
        }
    },
    "contactSet": {
        "title": {
            en: "Select one of your entities",
            fr: "Sélectionner une de vos entités"
        }, "select": {
            en: "Select an entity name",
            fr: "Sélectionner une entité"
        }, "submit": {
            en: "Validate",
            fr: "Valider"
        }
    },
    "faq": {
        "title": {
            en: "FAQ: requests",
            fr: "FAQ : Demandes"
        }, "description": {
            en: "Frequently Asked Questions about requests.",
            fr: "Questions fréquemment posées au sujet des demandes."
        }
    },
    "doc": {
        "title": {
            en: "Solutions documentation",
            fr: "Documentation des solutions"
        }, "description": {
            en: "Download documentation for each solution.",
            fr: "Télécharger les documents associés à chaque solution."
        }
    },
    "supportContacts": {
        "title": {
            en: "Digitech support contacts",
            fr: "Contacts du support Digitech"
        }, "description": {
            en: "Digitech support contacts information.",
            fr: "Information pour contacter le support Digitech."
        }
    },
    "adminMenu": {
        "title": {
            en: "Administrator menu",
            fr: "Menu administrateur"
        }, "description": {
            en: "Access to administration functions.",
            fr: "Accès aux fonctions d'administration."
        }, "titleFaq": {
            en: "FAQ Files management.",
            fr: "Gestion des fichiers FAQ."
        }, "titleDoc": {
            en: "Documentation files management.",
            fr: "Gestion des fichiers de documentation."
        }, "titleHealthCheck": {
            en: "Portal health checking report.",
            fr: "Rapport de fonctionnement du portail."
        }, "titleLogFile": {
            en: "Download Log Files.",
            fr: "Télécharger les fichiers Log."
        }, "titleResetPassword": {
            en: "Reset a user password.",
            fr: "Réinitialiser un mot de passe utilisateur."
        }
    },
    "adminFaqDoc": {
        "type": {
            en: "Type",
            fr: "Type"
        }, "fileName": {
            en: "File name",
            fr: "Nom du fichier"
        }, "lastUpdate": {
            en: "Last update",
            fr: "Dernière modification"
        }, "size": {
            en: "File Size",
            fr: "Taille du fichier"
        }, "delete": {
            en: "Delete",
            fr: "Supprimer"
        }, "titleSelect": {
            en: "Select files to upload",
            fr: "Sélectionner les fichiers à remonter"
        }, "select": {
            en: "Click here for selecting a file or drag it to here.",
            fr: "Cliquer ici pour sélectionner un fichier ou le faire glisser jusqu'ici."
        }, "upload": {
            en: "Upload files",
            fr: "Remonter des fichiers"
        }, "uploadSubmit": {
            en: "Start upload",
            fr: "Démarrer la remontée"
        }, "uploading": {
            en: "Uploading the file: ",
            fr: "Remontée du fichier : "
        }, "titleDelete": {
            en: "Are you sure to delete the file ?: ",
            fr: "Êtes-vous sûr de supprimer le fichier ?: "
        }
    },
    "adminDownload": {
        "titleSelect": {
            en: "Select files to download",
            fr: "Sélectionner le fichier à télécharger"
        }, "downloading": {
            en: "Downloading the file: ",
            fr: "Téléchargement du fichier: "
        }, "currentLogFile": {
            en: "Current Log file",
            fr: "Fichier Log courant"
        }, "backupLogFile": {
            en: "Backuped Log file",
            fr: "Fichier Log sauvegardé"
        }
    },
    "adminReports": {
        "title": {
            en: "Portal health checking report",
            fr: "Rapport de fonctionnement du portail"
        },
        "getHealthCheck": {
            en: "Health checking report",
            fr: "Rapport de fonctionnement"
        },
        "resetHealthCheck": {
            en: "Warning reset",
            fr: "Reinitialize les alertes"
        },
        "completed": {
            en: "Warnings have been reset correctly.",
            fr: "Les alertes ont été réinitialisées."
        }
    },
    "adminPassword": {
        "titleReset": {
            en: "Request a new password for a user",
            fr: "Demander un nouveau mot de passe pour un utilisateur"
        },"titleWaiting": {
            en: "A new password for the user has been requested",
            fr: "Un nouveau mot de passe pour l'utilisateur a été demandé"
        }, "emailAddress": {
            en: "Email Address",
            fr: "Adresse courriel de l'utilisateur"
        }, "request": {
            en: "Start the request",
            fr: "Lancer la demande"
        }, "completed": {
            en: "New password of the user ",
            fr: "Nouveau mot de passe de l'utilisateur "
        }
    },
    "size": {
        "bytes": {
            en: "Bytes",
            fr: "Octets"
        }, "kBytes": {
            en: "KB",
            fr: "Ko"
        }, "mBytes": {
            en: "MB",
            fr: "Mo"
        }
    },
    "status": {
        "title": {
            en: "Requests status",
            fr: "Etat des demandes"
        },
        "1": {
            en: "Undefined",
            fr: "À définir"
        },
        "2": {
            en: "Open",
            fr: "Ouverte"
        },
        "3": {
            en: "Waiting user feedback",
            fr: "Attente retour utilisateur"
        },
        "4": {
            en: "Closed",
            fr: "Fermée"
        },
        "11": {
            en: "Waiting Digitech response",
            fr: "Attente réponse Digitech"
        },
        "12": {
            en: "Workaround",
            fr: "Contournée"
        },
        "13": {
            en: "Under investigation",
            fr: "En cours d'analyse"
        },
        "14": {
            en: "To be recorded",
            fr: "À enregistrer"
        }
    },
    "severity": {
        "title": {
            en: "Severity",
            fr: "Sévérité"
        },
        "1": {
            en: "Undefined",
            fr: "À définir"
        },
        "2": {
            en: "(1) Showstopper",
            fr: "(1) Bloquant"
        },
        "3": {
            en: "(2) Urgent",
            fr: "(2) Urgente"
        },
        "4": {
            en: "(3) Not urgent",
            fr: "(3) Non urgente"
        }
    },
    "type": {
        "title": {
            en: "Type",
            fr: "Type"
        },
        "1": {
            en: "Undefined",
            fr: "À définir"
        },
        "2": {
            en: "Technical",
            fr: "Technique"
        },
        "3": {
            en: "Commercial",
            fr: "Commercial"
        },
        "6": {
            en: "Improvement",
            fr: "Evolution"
        },
        "7": {
            en: "Functional",
            fr: "Fonctionnel"
        },
        "8": {
            en: "Error",
            fr: "Erreur"
        },
        "9": {
            en: "Information",
            fr: "Information"
        }
    },
    "task": {
        "0": {
            en: "Not defined",
            fr: "Non défini"
        },
        "1": {
            en: "Task",
            fr: "Tâche"
        },
        "2": {
            en: "Appointment",
            fr: "Rdv"
        },
        "3": {
            en: "Call",
            fr: "Appel"
        },
        "4": {
            en: "Note",
            fr: "Note"
        },
        "6": {
            en: "Email",
            fr: "Email"
        },
    },
    "fluxObject": {
        "2": {
            en: "Opening",
            fr: "Ouverture"
        },
        "3": {
            en: "Requesting user feedback",
            fr: "Demande de retour utilisateur"
        },
        "4": {
            en: "Closing",
            fr: "Fermeture"
        },
        "11": {
            en: "Requesting Digitech response",
            fr: "Demande de réponse de Digitech"
        },
        "12": {
            en: "Setting a workaround",
            fr: "Mise en place d'un contournement"
        },
        "13": {
            en: "Start investigation",
            fr: "Démarrage de l'analyse"
        },
        "14": {
            en: "Creation",
            fr: "Création"
        }
    },
    "error": {
        "0": {
            en: "Ok",
            fr: "Ok"
        }, "1": {
            en: "Internal error",
            fr: "Erreur interne"
        }, "2": {
            en: "Network error",
            fr: "Erreur réseau"
        }, "3": {
            en: "You have been disconnected, please reconnect you (User not logged)",
            fr: "Vous avez été déconnecté, veuillez vous reconnecter, s'il vous plaît (utilisateur non connecté)"
        }, "4": {
            en: "You have been disconnected, please reconnect you (Wrong IP Address)",
            fr: "Vous avez été déconnecté, veuillez vous reconnecter, s'il vous plaît (Mauvaise IP Adresse)"
        }, "1000": {
            en: "Email or Password not present",
            fr: "Email ou Mot de passe absent"
        }, "1001": {
            en: "Invalid Email",
            fr: "Email invalide"
        }, "1002": {
            en: "Unknown user",
            fr: "Utilisateur inconnu"
        }, "1003": {
            en: "Primary user",
            fr: "Utilisateur primaire"
        }, "1004": {
            en: "Invalid password",
            fr: "Mot de passe non valide"
        }, "1005": {
            en: "Account not activated",
            fr: "Compte non activé"
        }, "1006": {
            en: "No Basic Authentification in Login message",
            fr: "Absence d'autentification basique dans le message de Login"
        }, "1007": {
            en: "No Token in the message from front end",
            fr: "Absence de Token dans le message envoyé du Front End"
        }, "1008": {
            en: "You have been disconnected, please reconnect you (Invalid Token)",
            fr: "Vous avez été déconnecté, veuillez vous reconnecter, s'il vous plaît (Token invalide)"
        }, "2000": {
            en: "Unknown Request",
            fr: "Demande inconnue"
        }, "2001": {
            en: "Request without any owner",
            fr: "Demande sans propriétaire"
        }, "3000": {
            en: "Products list empty",
            fr: "Liste des produits vide"
        }, "3100": {
            en: "No list of entities",
            fr: "Aucune liste d'entités"
        }, "3110": {
            en: "Entity not provided",
            fr: "Entité non transmise"
        }, "3200": {
            en: "Contact Id not provided",
            fr: "Id du contact non transmise"
        }, "3201": {
            en: "Invalid contact Id",
            fr: "Contact Id non valide"
        }, "4000": {
            en: "No File Path Id in the Upload request",
            fr: "Identifiant Path ID absent dans la demande de remontée de fichier"
        }, "4001": {
            en: "No File Name in the Upload request",
            fr: "Nom de fichier absent dans la demande de remontée de fichier"
        }, "4002": {
            en: "Invalid Path Id in the Upload request",
            fr: "Identifiant Path ID incorrect dans la demande de remontée de fichier"
        }, "4003": {
            en: "Invalid Year in the Path Id in the Upload request",
            fr: "Année invalide dans le Path ID de la demande de remontée de fichier"
        }, "4004": {
            en: "Cannot write the uploaded file on server",
            fr: "Impossible d'écrire le fichier remonté sur le serveur"
        }, "4005": {
            en: "Cannot write the information file on server",
            fr: "Impossible d'écrire le fichier information sur le serveur"
        }, "4105": {
            en: "File not found on server",
            fr: "Fichier non trouvé sur le serveur"
        }
    }
};
