/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2025                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontticketaddcomment.js                           !
  !  Desc. : Nodejs Digines Front Ticket Add Comment Modal      !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 22/03/2025                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React, {useRef} from 'react';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Avatar,
    IconButton, TextField, Typography
} from "@mui/material";
import {Editor} from '@tinymce/tinymce-react';

/*
--- Digitech products
*/
import {frontmsgFileUploadRequest_f, frontmsgState_e, frontmsgTicketAddCommentRequest_f} from './frontmsg';
import {FronterrorJSX} from "./fronterror";
import {Close} from "@mui/icons-material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import {frontticketdetailRefreshModal_f} from "./frontticketdetail";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import {frontticketaddfilesInitFields_f, FrontticketaddfilesJSX} from "./frontticketaddfiles";
import {frontinitKeyTinyMCE_f} from "./frontinit";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Request File Upload Request which will call after the Add Comment the Ticket
    */
    frontmsgFileUploadRequest_f(paramCtx);
    /*
    --- Refresh the Add Comment Modal
    */
    frontticketaddcommentRefreshModal_f(paramCtx);
}

/*
  +-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.modalTicketDetail.isAddCommentDisplay = false;
    /*
    --- Reset the message states
    */
    paramCtx.modalTicketDetail.isTicketReopen = false;
    paramCtx.modalTicketAddComment.msgState = frontmsgState_e.idle;
    paramCtx.modalTicketDetail.msgState = frontmsgState_e.idle;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the Ticket Detail Modal
    */
    frontticketdetailRefreshModal_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
  +-------------------------------------------------------------+
  ! Routine    : LocJSXTicketFields                             !
  ! Description: JSX Ticket Fields entry                        !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXTicketFields(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locEditorRef = useRef(null);
    /*
    --- Convert locale for Edit zone
    */
    const locLocale = (locCtx.config_o.locale === "fr-FR") ? "fr_FR" : "en";
    /*
    --- Return Form Field for Add Comment to Ticket
    */
    return (
        <Box component="form"
             noValidate sx={{mt: 0, pb: 0}}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="summary"
                defaultValue={locCtx.modalTicketAddComment.comment.summary}
                label={locTrans.comtransGet_m("ticketAddComment", "summary")}
                name="summary"
                autoFocus
                onChange={(paramEvent) => {
                    locCtx.modalTicketAddComment.comment.summary = paramEvent.target.value.trim();
                    frontticketaddcommentRefreshModal_f(locCtx);
                }}
            />
            <Editor
                apiKey={frontinitKeyTinyMCE_f(locCtx)}
                onInit={(paramEvent, paramEditor) => locEditorRef.current = paramEditor}
                initialValue={locCtx.modalTicketAddComment.comment.description}
                init={{
                    selector: 'textarea',
                    language: locLocale,
                    width: '100%',
                    menubar: true,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                    branding: false,
                    statusbar: false,
                    forced_root_block: 'div',
                    force_br_newlines: true,
                    force_p_newlines: false
                }}
                onBlur={(paramEvent) => {
                    locCtx.modalTicketAddComment.comment.description = paramEvent.target.getContent();
                    frontticketaddcommentRefreshModal_f(locCtx);
                }}
            />
        </Box>)
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXTicketAddComment                         !
! Description: JSX Ticket Add Comment display modal           !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXTicketAddComment(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Add Comment Modal
    */
    return (<div>
        <FronterrorJSX ctx={locCtx}/>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ConfirmationNumberIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketAddComment",
                            (locCtx.modalTicketDetail.isTicketReopen === true) ? "titleReopen" : "titleComment") +
                        " " + locCtx.modalTicketDetail.ticketReference}
                </Box>
            </Grid>
            <IconButton
                aria-label="close"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{mb: 0, pb: 0}}>
            <LocJSXTicketFields ctx={locCtx}/>
        </DialogContent>
        <DialogActions>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ml: 2, mr: 2}}>
                <Grid item xs>
                    <Button
                        variant="contained"
                        onClick={() => {
                            locCtx.modalTicketAddFiles.isDisplayed = true;
                            frontticketaddcommentRefreshModal_f(locCtx);
                        }}
                    >
                        {locTrans.comtransGet_m("ticketAddComment", "files")}
                    </Button>
                    <Typography variant="body2" component="span" align="left" sx={{ml: 2}}>
                        {locTrans.comtransGet_m("ticketAddComment", "nbFiles")}
                        <strong> {locCtx.modalTicketAddFiles.files_a.length} </strong>
                    </Typography>
                </Grid>
                <Grid item xs container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={locCtx.modalTicketAddComment.comment.summary.length < 1}
                        onClick={(paramEvent) => locSubmit_f(locCtx, paramEvent)}
                    >
                        {locTrans.comtransGet_m("ticketAddComment",
                            (locCtx.modalTicketDetail.isTicketReopen === true) ? "reopen" : "add")}
                    </Button>
                    <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)} sx={{ml: 1}}>
                        {locTrans.comtransGet_m("common", "cancel")}
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXWaiting                                     !
! Description: JSX User Waiting modal, local definition       !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;

    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ConfirmationNumberIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketAddComment",
                            (locCtx.modalTicketDetail.isTicketReopen === true) ? "titleReopen" : "titleComment") +
                        " " + locCtx.modalTicketDetail.ticketReference}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXResponseOkDisplay                        !
  ! Description: JSX Confirmation modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXResponseOkDisplay(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <HomeRepairServiceIcon/>
                </Avatar>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
            >
                <Box sx={{mt: 1, mb: 1}}>
                    <Typography variant="body2" component="div" align="center">
                        {locTrans.comtransGet_m("ticketAddComment",
                            (locCtx.modalTicketDetail.isTicketReopen === true) ? "reopenCompleted" : "addCompleted")}
                        <br/>
                        <strong>{locCtx.modalTicketDetail.ticketInformation.reference}</strong>
                    </Typography>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button
                type="submit"
                variant="contained"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
            >
                {locTrans.comtransGet_m("common", "return")}
            </Button>
        </DialogActions>
    </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontticketaddcommentInitFields_f              !
  ! Description: Initialise the Fields                          !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontticketaddcommentInitFields_f(paramCtx) {
    paramCtx.modalTicketAddComment.comment = {
        summary: "",
        type: "",
        description: ""
    };
    /*
    --- Reset the Files list
    */
    frontticketaddfilesInitFields_f(paramCtx,
        frontticketaddcommentRefreshModal_f,
        paramCtx.modalTicketDetail.ticketId,
        frontmsgTicketAddCommentRequest_f);
}

/*
+-------------------------------------------------------------+
! Routine : frontticketaddcommentRefreshModal_f               !
! Description: Request the refresh of Ticket Add Comment Modal!
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontticketaddcommentRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.ticketAddComment_s = !paramCtx.refresh_o.ticketAddComment_s;
    paramCtx.refresh_o.ticketAddComment_f(paramCtx.refresh_o.ticketAddComment_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontticketaddcommentJSX                          !
! Description: JSX Ticket Add Comment Modal                   !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontticketaddcommentJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locTicketAddComment_s, locTicketAddComment_f] = React.useState(false);
    locCtx.refresh_o.ticketAddComment_f = locTicketAddComment_f;
    locCtx.refresh_o.ticketAddComment_s = locTicketAddComment_s;
    /*
    --- Process the modal according the Tickets list request message state
    */
    let locModalContains;
    switch (locCtx.modalTicketAddComment.msgState) {
        case frontmsgState_e.idle:
        case frontmsgState_e.ok:
        case frontmsgState_e.inError:
        case frontmsgState_e.requested:
            locModalContains = (<LocJSXTicketAddComment ctx={locCtx}/>);
            break
        default:
            locModalContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (<div>
        <Dialog open={true} maxWidth="xl" fullWidth>
            {locModalContains}
        </Dialog>
        <Dialog open={locCtx.modalTicketAddFiles.isDisplayed} fullWidth maxWidth="xl">
            <FrontticketaddfilesJSX ctx={locCtx}/>
        </Dialog>
        <Dialog open={((locCtx.modalTicketAddComment.msgState === frontmsgState_e.requested) ||
            (locCtx.modalTicketAddFiles.uploadState === frontmsgState_e.requested))}>
            <LocJSXWaiting ctx={locCtx}/>
        </Dialog>
        <Dialog open={locCtx.modalTicketAddComment.msgState === frontmsgState_e.ok}>
            <LocJSXResponseOkDisplay ctx={locCtx}/>
        </Dialog>
    </div>);

}

