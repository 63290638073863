/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2025                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontadmindownload.js                              !
  !  Desc. : Nodejs Digines Front Admin Upload FAQ/DOC Modal    !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 22/03/2025                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Avatar,
    IconButton,
    CircularProgress
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

/*
--- Digitech products
*/
import {frontmsgAdminDownloadRequest_f, frontmsgState_e} from "./frontmsg";
import {FronterrorJSX} from "./fronterror";

/*=============== Exported objects =============================*/
/*
--- Admin file type
*/
export const frontadmindownloadType_e = {
    logCurrent: 0,
    logBackup: 1
}

/*=============== Local functions ==============================*/

/*
  +-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Return or Close Button              !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Reset the Modal
    */
    paramCtx.currentModal = frontmainModal_e.adminMenuModal;
    /*
    --- Reinitialize fields
    */
    frontadmindownloadInitFields_f(paramCtx);
    /*
    --- Refresh the Main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*
  +-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit buttons                      !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramType, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Set the File Type and Name
    */
    paramCtx.modalAdminDownload.type = paramType;
    paramCtx.modalAdminDownload.currentFile = (paramType === frontadmindownloadType_e.logBackup) ?
        paramCtx.config_o.logBackupFilename : paramCtx.config_o.logCurrentFilename;
    /*
    --- Start the Files Download
    */
    frontmsgAdminDownloadRequest_f(paramCtx);
    /*
    --- Refresh the current dialog
    */
    frontadmindownloadRefreshModal_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminDownload                            !
! Description: JSX Admin Download display modal               !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminDownload(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Add Comment Modal
    */
    return (<div>
        <FronterrorJSX ctx={locCtx}/>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <SaveAltIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("adminDownload", "titleSelect")}
                </Box>
            </Grid>
            <IconButton
                aria-label="close"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{mt: 4, textAlign: "center"}}>
            <Button sx={{mr: 2}} variant="contained" endIcon={<SaveAltIcon/>}
                    onClick={(paramEvent) => locSubmit_f(locCtx, frontadmindownloadType_e.logCurrent, paramEvent)}>
                {locTrans.comtransGet_m("adminDownload", "currentLogFile")}
            </Button>
            <Button sx={{ml: 2}} variant="contained" endIcon={<SaveAltIcon/>}
                    onClick={(paramEvent) => locSubmit_f(locCtx, frontadmindownloadType_e.logBackup, paramEvent)}>
                {locTrans.comtransGet_m("adminDownload", "backupLogFile")}
            </Button>
        </DialogContent>
        <DialogActions sx={{mt: 1, pt: 0, mr: 2}}>
            <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                {locTrans.comtransGet_m("common", "cancel")}
            </Button>
        </DialogActions>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXWaiting                                     !
! Description: JSX Waiting modal, local definition            !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <SaveAltIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("adminDownload", "downloading") +
                        locCtx.modalAdminDownload.currentFile}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontadmindownloadInitFields_f                 !
  ! Description: Initialise the Fields                          !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontadmindownloadInitFields_f(paramCtx) {
    paramCtx.modalAdminDownload = {
        type: frontadmindownloadType_e,
        downloadState: frontmsgState_e.idle,
        currentFile: ""
    };
}

/*
+-------------------------------------------------------------+
! Routine : frontadmindownloadRefreshModal_f                  !
! Description: Request the refresh of the Admin Download Modal!
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontadmindownloadRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.adminDownload_s = !paramCtx.refresh_o.adminDownload_s;
    paramCtx.refresh_o.adminDownload_f(paramCtx.refresh_o.adminDownload_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontadmindownloadJSX                             !
! Description: JSX Admin Download files Modal                 !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontadmindownloadJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locAdminDownload_s, locAdminDownload_f] = React.useState(false);
    locCtx.refresh_o.adminDownload_f = locAdminDownload_f;
    locCtx.refresh_o.adminDownload_s = locAdminDownload_s;
    /*
    --- Process the modal according the Upload request message state
    */
    let locModalContains;
    switch (locCtx.modalAdminDownload.downloadState) {
        case frontmsgState_e.idle:
        case frontmsgState_e.ok:
        case frontmsgState_e.inError:
            locModalContains = (<LocJSXAdminDownload ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locModalContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (
        <Dialog open={true} maxWidth="md">
            {locModalContains}
        </Dialog>);
}
