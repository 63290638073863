/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2025                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontinit.js                                       !
  !  Desc. : Nodejs Digines Front server initialization         !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 23/03/2025                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
/*
--- Digitech products
*/
import {backinterfaceError, backinterfaceTicketStatus} from "./diginesback/backinterface";
import {Comtrans_c} from './diginescommon/comtrans'
import {frontconfig} from './frontconfig';
import {fronttrans} from './fronttrans';
import {frontmsgState_e} from './frontmsg';
import {frontmainModal_e} from "./frontmain";
import {Commisc_c} from "./diginescommon/commisc/commisc";
import {frontfaqdocType_e} from "./frontfaqdoc";
import {frontreportsType_e} from "./frontadminreports";
import {frontadmindownloadType_e} from "./frontadmindownload";

/*=============== Exported functions ==========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontinit_f                                    !
  ! Description: Initialize the front server                    !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontinit_f(paramCtx) {
    /*
    --- Create Config object
    */
    paramCtx.config_o = frontconfig;
    /*
    --- Create Common Misc object
    */
    paramCtx.misc_o = new Commisc_c();
    /*
    --- Create Translation object
    */
    paramCtx.trans_o = new Comtrans_c(paramCtx.config_o.locale, fronttrans);
    /*
    --- Set the User not connected
    */
    paramCtx.user_o = {
        connection: frontmsgState_e.idle,
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        clientId: "",
        clientName: "",
        clientType: "",
        password: ""
    }
    /*
    --- In case of error: current Error information
    */
    paramCtx.error_o = {
        inError: false,
        code: backinterfaceError.ok,
        message: ""
    }
    /*
    --- Initialize the Refresh functions
    */
    paramCtx.refresh_o = {};
    /*
    --- Test state: True=Test version
    */
    paramCtx.test = false;
    /*
    --- Backend Token
    */
    paramCtx.token = "";
    /*
    --- Current Window information
    */
    paramCtx.window_o = {
        width: 0,
        height: 0
    }
    /*
    --- Initialize the current Modal to open
    */
    paramCtx.currentModal = frontmainModal_e.loginModal;
    /*
    --- Main Page information
    */
    paramCtx.pageMain = {
        msgState: frontmsgState_e.idle,
    };
    /*
    --- Modal Tickets List information
    */
    paramCtx.modalTicketsList = {
        msgState: frontmsgState_e.idle,
        ticketsStatus: backinterfaceTicketStatus.open,
        ticketsList: [],
    };
    /*
    --- Modal Ticket Detail information
    */
    paramCtx.modalTicketDetail = {
        msgState: frontmsgState_e.idle,
        ticketId: "0",
        ticketReference: "",
        ticketInformation: {},
        isDetailDisplay: false,
        isAddCommentDisplay: false,
        isTicketReopen: false,
        detailText: "",
        isAllCollapsed: true,
        isCollapseRefresh: true,
        collapseStatus: [],
        collapseIndex: 0,
        activeId: "date",
        sortSens: {
            date: ""
        },
        searchText: "",
        searchChange: false
    };
    /*
    --- Modal Tickets Creation information
    */
    paramCtx.modalTicketCreation = {
        msgProductListState: frontmsgState_e.idle,
        msgTicketCreationState: frontmsgState_e.idle,
        productsList: [],
        ticket: {
            id: "",
            reference: "",
            summary: "",
            product: "",
            type: "",
            severity: "",
            description: "",
            files: []
        }
    }
    /*
    --- Modal Tickets Add Comment information
    */
    paramCtx.modalTicketAddComment = {
        msgState: frontmsgState_e.idle,
        comment: {
            summary: "",
            description: ""
        }
    }
    /*
    --- Modal Tickets Add Files information
    */
    paramCtx.modalTicketAddFiles = {
        ticketId: "",
        isDisplayed: false,
        refreshFunction_f: () => {
        },
        files_a: [],
        filesLink_a: [],
        nbUploadsDone: 0,
        uploadState: frontmsgState_e.idle,
        afterUploadCallback_f: () => {
        }
    }
    /*
    --- Modal Password Change information
    */
    paramCtx.modalPasswordChange = {
        msgState: frontmsgState_e.idle,
        oldPassword: "",
        newPassword: "",
        newPasswordCheckedOk: true,
        oldPasswordCheckedOk: true,
        twicePasswordCheckedOk: true
    }
    /*
    --- Modal Password Reset information
    */
    paramCtx.modalPasswordReset = {
        isDisplayed: false,
        msgState: frontmsgState_e.idle,
        title: ""
    }
    /*
    --- Modal Client Setting
    */
    paramCtx.modalClientSet = {
        isDisplayed: false,
        parentModal: frontmainModal_e.loginModal,
        msgGetClientsListState: frontmsgState_e.idle,
        msgPutClientSetState: frontmsgState_e.idle,
        searchText: "",
        clientsList_a: [],
        selectedClientIndex: -1
    }
    /*
    --- Modal Contact Setting
    */
    paramCtx.modalContactSet = {
        isDisplayed: false,
        parentModal: frontmainModal_e.loginModal,
        msgState: frontmsgState_e.idle,
        contactsList_a: [],
        selectedContactIndex: -1
    }
    /*
    --- Modal FAQ/Documentation
    */
    paramCtx.modalFaqDoc = {
        type: frontfaqdocType_e.faq,
        msgState: frontmsgState_e.idle,
        faqDocList_a: []
    }
    /*
    --- Modal Admin FAQ/Documentation
    */
    paramCtx.modalAdminFaqDoc = {
        type: frontfaqdocType_e.faq,
        msgGetDirState: frontmsgState_e.idle,
        msgDeleteState: frontmsgState_e.idle,
        faqDocDir_a: [],
        fileToDelete: ""
    }
    /*
    --- Modal Admin FAQ/Documentation Upload
    */
    paramCtx.modalAdminUpload = {
        type: frontfaqdocType_e.faq,
        files_a: [],
        nbUploadsDone: 0,
        uploadState: frontmsgState_e.idle,
        currentFile: ""
    }
    /*
    --- Modal Reports display
    */
    paramCtx.modalAdminReports = {
        type: frontreportsType_e.healthCheck,
        getHealthCheck: false,
        msgState: frontmsgState_e.idle
    }
    /*
    --- Modal Admin Files Download
    */
    paramCtx.modalAdminDownload = {
        type: frontadmindownloadType_e,
        downloadState: frontmsgState_e.idle,
        currentFile: ""
    }
    /*
    --- Modal Admin Password management
    */
    paramCtx.modalAdminPassword = {
        msgState: frontmsgState_e.idle,
        userEmail: "",
        userNewPassword: ""
    }
}


/*+-------------------------------------------------------------+
  ! Routine    : frontinitKeyTinyMCE_f                          !
  ! Description: Initialize the Tiny MCE API Key                !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Tiny MCE API Key                                     !
  +-------------------------------------------------------------+
*/
export function frontinitKeyTinyMCE_f(paramCtx) {
    /*
    --- Get the Client ID in integer
    */
    const locClientId = parseInt(paramCtx.user_o.clientId);
    /*
    --- If the client ID is even, then take the Key 1 else the Key 2;
    */
    let locKey;
    const locDivideTwo = Math.round(locClientId / 2);
    if (locClientId === (locDivideTwo * 2)) {
        locKey = paramCtx.config_o.key1TinyMCE;
    } else {
        locKey = paramCtx.config_o.key2TinyMCE;
    }
    /*
    --- Return the found Key
    */
    return locKey;
}