/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2025                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontadminreports.js                               !
  !  Desc. : Nodejs Digines Front Display Reports Modal         !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 22/03/2025                                         !
  !                                                             !
  !  0.1: Creation                                              !
  !  1.0: Adaptation to new platform                            !
  !  1.1: Admin reset password and Log Files download           !
  !  1.2: Add creation record in ticket detail                  !
  !  1.3: On reopen set state 'Requesting Digitech response'    !
  !  2.1: New display for Ticket details                        !
  !  2.2.0: Severity text and Product in Tickets list           !
  !  2.3.0: Fix URL for Digitech support                        !
  !  2.4.0: Add Reset HealthCheck for Admin                     !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton, Typography
} from "@mui/material";
import {Close} from "@mui/icons-material";

/*
--- Digitech products
*/
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {backinterfaceApi} from "./diginesback/backinterface";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import {FronterrorJSX} from "./fronterror";
import {frontmsgResetHealthCheck_f, frontmsgState_e} from "./frontmsg";

/*=============== Exported objects =============================*/
/*
--- Types of report
*/
export const frontreportsType_e = {
    healthCheck: 0
};

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal and return to Admin Menu
    */
    paramCtx.currentModal = frontmainModal_e.adminMenuModal;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminReportsMain                         !
! Description: JSX Main Reports modal                         !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminReportsMain(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Add Comment Modal
    */
    return (
        <Dialog open={true} maxWidth="md">
            <FronterrorJSX ctx={locCtx}/>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <FactCheckIcon/>
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m("adminReports", "title")}
                    </Box>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                    sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{mt: 4, textAlign: "center"}}>
                <Button sx={{mr: 2}}
                        variant="contained"
                        onClick={() => {
                            locCtx.modalAdminReports.getHealthCheck = true;
                            frontadminreportsRefreshModal_f(locCtx)
                        }}>
                    {locTrans.comtransGet_m("adminReports", "getHealthCheck")}
                </Button>
                <Button sx={{ml: 2}}
                        onClick={() => {
                            frontmsgResetHealthCheck_f(locCtx);
                        }}>
                    {locTrans.comtransGet_m("adminReports", "resetHealthCheck")}
                </Button>
            </DialogContent>
            <DialogActions sx={{mt: 1, pt: 0, mr: 2}}>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                    {locTrans.comtransGet_m("common", "cancel")}
                </Button>
            </DialogActions>
        </Dialog>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXGetHealthCheckReport                        !
! Description: JSX Get Health Check Report                    !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXGetHealthCheckReport(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Build the Health Check URI
    */
    const locHostname = locCtx.config_o.backServerHostname;
    const locPort = locCtx.config_o.backServerPort;
    let locUri = "https://" + locHostname + ":" + locPort + "/" + backinterfaceApi.getHealthCheck;
    /*
    --- Return Health Check Report Modal
    */
    return (
        <Dialog open={locCtx.modalAdminReports.getHealthCheck} fullWidth maxWidth="xl">
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <FactCheckIcon/>
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m("adminMenu", "titleHealthCheck")}
                    </Box>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        locCtx.modalAdminReports.getHealthCheck = false;
                        frontadminreportsRefreshModal_f(locCtx);
                    }}
                    sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{pb: 0}}>
                <iframe
                    src={locUri}
                    title={locTrans.comtransGet_m("adminMenu", "titleHealthCheck")}
                    style={{width: '100%', minHeight: '450px'}}/>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        locCtx.modalAdminReports.getHealthCheck = false;
                        frontadminreportsRefreshModal_f(locCtx);
                    }}
                    sx={{mt: 0, mr: 2, pt: 0}}>
                    {locTrans.comtransGet_m("common", "return")}
                </Button>
            </DialogActions>
        </Dialog>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXResponseOkDisplay                        !
  ! Description: JSX Confirmation modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXResponseOkDisplay(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (
        <Dialog open={locCtx.modalAdminReports.msgState === frontmsgState_e.ok}>
            <DialogTitle>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <FactCheckIcon/>
                    </Avatar>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                >
                    <Box sx={{mt: 1, mb: 1}}>
                        <Typography variant="body2" component="div" align="center">
                            {locTrans.comtransGet_m("adminReports", "completed")}
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        locCtx.modalAdminReports.msgState = frontmsgState_e.idle;
                        frontadminreportsRefreshModal_f(locCtx);
                    }}
                >
                    {locTrans.comtransGet_m("common", "return")}
                </Button>
            </DialogActions>
        </Dialog>);
}

/*=============== Exported functions ===========================*/

/*
+-------------------------------------------------------------+
! Routine : frontadminreportsRefreshModal_f                   !
! Description: Request the refresh of the Reports Modal       !
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontadminreportsRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.adminReports_s = !paramCtx.refresh_o.adminReports_s;
    paramCtx.refresh_o.adminReports_f(paramCtx.refresh_o.adminReports_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontadminreportsJSX                              !
! Description: JSX Admin Reports Modal                        !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontadminreportsJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locReports_s, locReports_f] = React.useState(false);
    locCtx.refresh_o.adminReports_f = locReports_f;
    locCtx.refresh_o.adminReports_s = locReports_s;
    /*
    --- Return the Modal
    */
    return (
        <div>
            <LocJSXAdminReportsMain ctx={locCtx}/>
            <LocJSXGetHealthCheckReport ctx={locCtx}/>
            <LocJSXResponseOkDisplay ctx={locCtx}/>
        </div>
    );
}

