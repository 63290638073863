/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Ines Backend Code                              !
  !     (C) COPYRIGHT DIGITECH 2022-2025                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : backinterface.js                                   !
  !  Desc. : Nodejs Digines Backend server interface definitions!
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 24/03/2025                                         !
  !                                                             !
  !  0.1.0: Creation                                            !
  !  1.2.0: Add automatic INES reconnection and monitoring      !
  +-------------------------------------------------------------+
*/
/*=============== Exported objects =============================*/
/*
--- HTTPS Back Server port
*/
// export const backinterfaceBackServerPort = 3746;
export const backinterfaceBackServerPort = 443;

/*
--- HTTPS Status Codes
*/
export const backinterfaceStatus = {
    ok: 200,
    badRequest: 400,
    emptyList : 405,
    unauthorized: 401,
    internalServerError: 500,
};

/*
--- HTTPS Error code
*/
export const backinterfaceError = {
    // --- Generic code
    ok: 0,
    internalError: 1,
    networkError: 2,
    userNotLogged: 3,
    wrongOriginIpAddress: 4,
    userNotAdmin: 5,
    // --- Login Error
    loginNoEmailNoPassword: 1000,
    loginInvalidEmail: 1001,
    loginUnknownUser: 1002,
    loginNoSecondary: 1003,
    loginInvalidPassword: 1004,
    loginNotActivated: 1005,
    loginNoBasicAuthorisation: 1006,
    loginNoToken: 1007,
    loginInvalidToken: 1008,
    // --- Ticket Error
    ticketNoId: 2000,
    ticketNoOwner: 2001,
    // --- Product Error
    noProduct: 3000,
    // --- Clients list and set Error
    clientsNoList: 3100,
    clientNotProvided: 3110,
    clientNoInfo: 3120,
    // --- Contact Set Error
    contactIdNotProvided: 3200,
    contactIdInvalid: 3201,
    // --- File Upload Error
    uploadNoPathId: 4000,
    uploadNoName: 4001,
    uploadInvalidPathId: 4002,
    uploadInvalidYear: 4003,
    uploadWritingFile: 4004,
    uploadWritingInfo: 4005,
    // --- File Download Error
    downloadNoFileId: 4100,
    downloadInvalidFileId: 4101,
    downloadInvalidYear: 4102,
    downloadReadingFile: 4103,
    downloadInfoNotFound: 4104,
    downloadFileNotFound: 4105,
    downloadReadingInfo: 4106,
    // --- File Delete Error
    deleteNoName: 4200,
    deleteNotAuthorized: 4201
};

/*
--- HTTPS REST Paths
*/
export const backinterfaceApi = {
    getLogin: "login",
    getLogout: "logout",
    getClientsList: "clientslist",
    getClientInfo: "clientinfo",
    putClientSet: "clientset",
    putContactSet: "contactset",
    getTicketsList: "ticketslist",
    getTicketDetail: "ticketdetail",
    getProductsList: "productslist",
    postTicketCreation: "ticketcreation",
    putTicketUpdate: "ticketupdate",
    postTicketAddComment: "ticketaddcomment",
    putChangePassword: "changepassword",
    postResetPassword: "resetpassword",
    postAdminResetPassword: "adminresetpassword",
    postFileUpload: "fileupload",
    getFileDownload: "filedownload",
    getFaq: "faq",
    getFaqDir: "faqDir",
    postFaq: "faqUpload",
    deleteFaq: "faqDelete",
    getDoc: "doc",
    getDocDir: "docDir",
    postDoc: "docUpload",
    deleteDoc: "docDelete",
    getHealthCheck: "healthCheck",
    postResetHealthCheck: "resetHealthCheck",
    getLogFileCurrent: "logCurrent",
    getLogFileBackup: "logBackup"
};

/*
--- Ines Tickets Status
*/
export const backinterfaceTicketStatus = {
    open: 1,
    close: 3,
    all: 4
};

/*
--- User Type
*/
export const backinterfaceUserType = {
    unknown: 0,
    contact: 1,
    reader: 2,
    writer: 3,
    admin: 4
};